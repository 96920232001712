import { gql } from 'apollo-boost';

const REGISTER_VIEWER = gql`
  mutation registerViewer($domain: String!) {
    registerViewer(domain: $domain) {
      id
      domain
      token
    }
  }
`;

export default REGISTER_VIEWER;
