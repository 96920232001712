import React from 'react';
import { useTranslation } from 'react-i18next';
import { CPage } from './styled/StyledComponentsPages';
import useShow from '../../hooks/useShow';

const VotemoPage = () => {
  // Using translations for different pages for now. We might request the content at some point
  const { t } = useTranslation();
  const { language } = useShow();

  const pageClassList = ['page', 'votemo'];
  return (
    <CPage className={pageClassList.join(' ')}>
      <div className="page-inner">
        <div className="page__header__votemo">
          <h1>{t('pages.votemo.title')}</h1>
        </div>
        <div className="page__content__votemo">
          { language !== 'et' ? (
            <p>{t('pages.votemo.content_p1')}
              {' '}<a href="mailto:support@votemo.eu.">support@votemo.eu</a>
            </p>
          ) : (
            <>
              <p>{t('pages.votemo.content_p1')}
                {' '}
                <a
                  href="https://votemo.eu"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  votemo.eu
                </a>
              </p>
              <p>{t('pages.votemo.content_p2')}
                {' '}<a href="mailto:support@votemo.eu.">support@votemo.eu</a>
              </p>
            </>
          )}
        </div>
      </div>
    </CPage>
  );
};

export default VotemoPage;
