import { useLazyQuery } from '@apollo/react-hooks';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import GET_COMMENTS from '../../graphql/queries/getComments';
import GET_COMMENT_UPDATES from '../../graphql/queries/getCommentUpdates';
import useViewer from '../viewers';

const useCommentUpdates = (handleUpdates, viewer, moduleId) => {
  const [cursor, setCursor] = useState('');
  const [fetchUpdates, { data, loading, error }] = useLazyQuery(GET_COMMENT_UPDATES, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (loading || !data || !data.commentUpdates) {
      return;
    }

    const result = data.commentUpdates;
    handleUpdates({
      created: result.new,
      updated: result.updated,
    });
    setCursor(result.cursor);
  }, [loading, data]);

  const refetch = useCallback(() => {
    if (!viewer) return Promise.resolve(null);
    return fetchUpdates({
      variables: { module_id: moduleId, cursor, uid: viewer.id },
    });
  }, [moduleId, viewer, cursor]);

  return [data, {
    cursor,
    loading: useMemo(
      () => loading || !data,
      [loading, data],
    ),
    error,
    refetch,
  }];
};

const useComments = (handleNewComments, viewer, moduleId) => {
  const [cursor, setCursor] = useState('');
  const [fetch, { data, loading, error }] = useLazyQuery(GET_COMMENTS, {
    fetchPolicy: 'no-cache',
  });

  // update cursor and handle new comments on data
  useEffect(() => {
    if (loading || !data || !data.comments) {
      return;
    }
    const result = data.comments;
    handleNewComments(result.comments);
    setCursor(result.cursor);
  }, [data]);

  const loadMore = useCallback(
    () => {
      if (!viewer) return Promise.resolve(null);
      return fetch({
        variables: { module_id: moduleId, cursor, uid: viewer.id },
      });
    },
    [moduleId, cursor, viewer],
  );

  const refetch = useCallback(
    (fetchCursor = '') => {
      if (!viewer) return Promise.resolve(null);
      return fetch({
        variables: { module_id: moduleId, cursor: fetchCursor, uid: viewer.id },
      });
    },
    [moduleId, viewer],
  );

  return [data, {
    cursor,
    loading: useMemo(
      () => loading || !data,
      [loading, data],
    ),
    error,
    refetch,
    loadMore,
  }];
};

const useGetComments = (module_id, autoload = true) => {
  const [comments, setComments] = useState([]);
  const [viewer] = useViewer();

  const handleNewComments = useCallback(
    (data) => {
      const newComments = data.map((item) => {
        if (item.replies) return item;

        return { ...item, replies: { comments: [] } };
      });
      setComments(newComments);
    },
    [setComments],
  );

  const handleUpdateComments = useCallback(
    ({ created, updated }) => {
      const newComments = [...created, ...updated].map((item) => {
        if (item.replies) return item;

        return { ...item, replies: { comments: [] } };
      });
      setComments(newComments);
    },
    [setComments],
  );

  const [, {
    loading: loadingComments,
    error: errorComments,
    refetch: refetchComments,
    loadMore: loadMoreComments,
  }] = useComments(handleNewComments, viewer, module_id);
  const [, {
    loading: loadingUpdates,
    error: errorUpdates,
    refetch: refetchUpdates,
  }] = useCommentUpdates(handleUpdateComments, viewer, module_id);

  const loading = useMemo(
    () => loadingComments || loadingUpdates,
    [loadingComments, loadingUpdates],
  );
  const error = useMemo(
    () => errorComments || errorUpdates,
    [errorComments, errorUpdates],
  );

  // Fetch comments when moduleId or uid change
  useEffect(() => {
    if (!module_id || !viewer || !autoload) {
      return;
    }

    refetchComments();
    refetchUpdates();
  }, [module_id, viewer, autoload]);

  return [comments, {
    refetch: refetchComments,
    loadMore: loadMoreComments,
    update: refetchUpdates,
    loading,
    error,
  }];
};

export default useGetComments;
