import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import useShow from '../../../../hooks/useShow';

import PROCESS_PIN from '../../../../graphql/mutations/processPin';
import { trackCustomEvent } from '../../../../library/gaHelper';

const PinCodeModal = (props) => {
  const {
    phonenumber,
    secretMessage,
    optionData,
    voteWeight,
    setShowPinModal,
    setErrorMessage,
    setHasError,
    postVote,
    setIsVoting,
  } = props;
  const { currentModule } = useShow();
  const { t } = useTranslation();
  const [pinTriesLeft, setPinTriesLeft] = useState(3);
  const [pinCode, setPinCode] = useState('');
  const [loadingVoteProcess, setLoadingVoteProcess] = useState(false);
  const [processPin, { loading }] = useMutation(PROCESS_PIN);
  useEffect(() => {
    if (loading) {
      setIsVoting(true);
    } else {
      setIsVoting(false);
    }
    // eslint-disable-next-line
  }, [loading]);

  const processPinCode = async () => {
    setPinTriesLeft(pinTriesLeft - 1);

    const payload = {
      phonenumber,
      gw_sid: secretMessage,
      pin: pinCode,
      module_id: currentModule.id,
      option_id: optionData.id,
      megavote: voteWeight !== 1,
    };

    try {
      const response = await processPin({
        variables: {
          payload,
        },
      });
      const { success, message } = response.data.processPin;
      if (success) {
        setLoadingVoteProcess(true);
        trackCustomEvent({
          category: 'voting',
          action: 'Entered correct PIN',
          label: optionData.title,
        });
        const voteResponse = await postVote();
        if (voteResponse) {
          setLoadingVoteProcess(false);
        }
      } else {
        if (message === 'error_incorrect_pin') {
          trackCustomEvent({
            category: 'voting',
            action: 'Entered wrong PIN',
            label: optionData.title,
          });
          setPinCode('');
          if (pinTriesLeft - 1 === 0) {
            trackCustomEvent({
              category: 'voting',
              action: 'Entered wrong Pin - No more tries',
              label: optionData.title,
            });
            setShowPinModal(false);
            setErrorMessage(t('modules.vote.form.no_more_pin_tries'));
            setHasError(true);
          }
        }
        if (message === 'error_unknown') {
          trackCustomEvent({
            category: 'voting',
            action: 'Unknown error',
            label: optionData.title,
          });
          setShowPinModal(false);
          setErrorMessage(t('modules.vote.form.error_unknown'));
          setHasError(true);
        }
        if (message === 'error_insufficient_funds') {
          trackCustomEvent({
            category: 'voting',
            action: 'Error: Insufficient funds',
            label: optionData.title,
          });
          setShowPinModal(false);
          setErrorMessage(t('modules.vote.form.error_insufficient_funds'));
          setHasError(true);
        }
      }
    } catch (e) {
      trackCustomEvent({
        category: 'voting',
        action: 'Unknown error',
        label: optionData.title,
      });
      setHasError(true);
    }
  };

  return (
    <div className={loading || loadingVoteProcess ? 'modal-inner loading' : 'modal-inner'}>
      {(loading || loadingVoteProcess) && (
        <div className="modal-spinner">
          <Loader
            type="Grid"
            color="var(--color-primary)"
            height={100}
            width={100}
            style={{
              margin: 'auto auto',
            }}
          />
        </div>
      )}
      <div className="modal__content">
        <h2>
          {t('modules.vote.form.enter_pin')}
        </h2>
        <p>{t('modules.vote.form.enter_pin_detail')}</p>
        {voteWeight !== 1 ? (
          <p>{currentModule.megavote_price}€ {t('modules.vote.form.enter_pin_detail_description')}</p>
        ) : (
          <p>{currentModule.price}€ {t('modules.vote.form.enter_pin_detail_description')}</p>
        )}
        <p>{t('modules.vote.form.pin_tries_left')} {pinTriesLeft}</p>
        <div>
          <input
            value={pinCode}
            onChange={(event) => setPinCode(event.target.value)}
            type="number"
            className="modal__input"
          />
        </div>
      </div>
      <div className="modal__actions">
        <button
          disabled={loading || loadingVoteProcess || pinCode === ''}
          type="button"
          className="actions__confirm"
          onClick={() => processPinCode()}
        >
          {t('modules.vote.form.confirm')}
        </button>
      </div>
    </div>
  );
};

export default PinCodeModal;
