import { useMutation } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import OutsideClickHandler from 'react-outside-click-handler';
import { Redirect, useHistory } from 'react-router-dom';
import ModalConfig from '../../../../config/ModalConfig';
import VOTE_ADD from '../../../../graphql/mutations/voteAdd';
import useShow from '../../../../hooks/useShow';
import { incrementVoteCount } from '../../../../library/voteLimit';
import SuccessfulVote from '../Modal/SuccessfulVote';
import ErrorModal from '../Modal/VoteError';
import VoteModalFree from '../Modal/VoteFree';
import VoteModalPaid from '../Modal/VotePaid';

ModalConfig();

const VoteButtons = ({ optionData, handleClose, setLoading, nextUrl }) => {
  const history = useHistory();
  const { next: nextModule, currentModule, trackingId } = useShow();
  const { t } = useTranslation();
  const [voteWeight, setVoteWeight] = useState(1);
  const [isVoting, setIsVoting] = useState(false);
  const [isError, setError] = useState(false);
  const [isVoted, setVoted] = useState(false);
  const [results, setResults] = useState([]);
  const [canShowResults, setCanShowResults] = useState(false);
  const [startVoteModalQueue, setStartVoteModalQueue] = useState(false);
  const [createVote] = useMutation(VOTE_ADD);

  const onOptionClick = (event) => {
    event.stopPropagation();
    handleClose();
  };
  const voteClick = async (event, weight) => {
    event.stopPropagation();
    setVoteWeight(weight);
    setStartVoteModalQueue(true);
  };
  const handleClickNext = () => {
    handleClose();
    if (nextUrl) history.replace(nextUrl);
  };

  const freeVote = async (event) => {
    event.stopPropagation();
    setLoading(true);
    const votePayload = {
      question_id: currentModule.id,
      answer_id: optionData.id,
      weight: 1,
      is_paid: currentModule.is_paid,
      tracking_id: trackingId,
    };
    try {
      const result = await createVote({
        variables: {
          payload: votePayload,
          showResults: currentModule.show_results,
        },
      });
      if (result.data.createVote.success) {
        if (result.data.createVote.showResults && result.data.createVote.results) {
          setCanShowResults(true);
        }
        setResults(result.data.createVote.results);
        setLoading(false);
        setVoted(true);
        setStartVoteModalQueue(true);
        incrementVoteCount(currentModule);
      }
    } catch (e) {
      setError(true);
    }
  };

  const closeModal = (event) => {
    if (!isVoting) {
      event.stopPropagation();
      handleClose();
    }
  };
  const handleVoteAgainClick = (event) => {
    closeModal(event);
  };
  const renderSuccessfulVoteModal = () => {
    if (canShowResults && results) {
      return (
        <Redirect
          to={{
            pathname: `/${currentModule.id}`,
            state: {
              view: 'results',
              fetchVotes: true,
            },
          }}
        />
      );
    }
    return (
      <SuccessfulVote
        handleVoteAgainClick={handleVoteAgainClick}
        onClickNext={handleClickNext}
      />
    );
  };

  return (
    <>
      <ReactModal
        isOpen={startVoteModalQueue}
        onRequestClose={(event) => closeModal(event)}
        className="vote-modal animated zoomInUp"
      >
        {!isVoted ? (
          <>
            {!currentModule.is_paid ? (
              <VoteModalFree
                closeModal={closeModal}
                optionData={optionData}
                setIsVoting={setIsVoting}
                nextUrl={nextModule >= 0 ? `/${nextModule}` : null}
              />
            ) : (
              <VoteModalPaid
                closeModal={closeModal}
                optionData={optionData}
                setIsVoting={setIsVoting}
                voteWeight={voteWeight}
                nextUrl={nextModule >= 0 ? `/${nextModule}` : null}
              />
            )}
          </>
        ) : (
          <>
            {isError && (
              <div className="modal"><ErrorModal handleVoteAgainClick={handleVoteAgainClick} /></div>
            )}
            {!isError && isVoted && (
              renderSuccessfulVoteModal()
            )}
          </>
        )}
      </ReactModal>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (!startVoteModalQueue) {
            handleClose();
          }
        }}
      >
        <div
          className="option__vote-options"
          onClick={(event) => onOptionClick(event)}
          onKeyPress={(event) => onOptionClick(event)}
          role="button"
          tabIndex="-1"
        >
          <div className="vote-option__container animated fadeInLeft">
            {!!currentModule.megavote_amount && !!currentModule.megavote_price && !!currentModule.is_paid && (
              <button
                className="vote-option vote-option__megavote"
                type="button"
                onClick={(event) => voteClick(event, currentModule.megavote_amount)}
              >
                <div className="option-count counter">
                  {currentModule.megavote_amount}
                </div>
                <div className="option-count">
                  {t('modules.vote.form.votes')}
                </div>
                <div className="divider" />
                <div className="option-price">
                  {currentModule.megavote_price}
                </div>
              </button>
            )}

            {!!currentModule.is_paid && !!currentModule.price && (
              <button
                className="vote-option vote-option__regular"
                type="button"
                onClick={(event) => voteClick(event, 1)}
              >
                <div className="option-count counter">
                  1
                </div>
                <div className="option-count">
                  {t('modules.vote.form.vote')}
                </div>

                <div className="divider" />
                <div className="option-price">
                  {currentModule.price}
                </div>

              </button>
            )}
            {!currentModule.is_paid && (
              <button
                className="vote-option vote-option__regular"
                type="button"
                onClick={(event) => {
                  if (currentModule.collect_contact_details) {
                    voteClick(event, 1);
                  } else {
                    freeVote(event, 1);
                  }
                }}
              >
                <div className="option-count fw-bold">
                  {t('modules.vote.form.question_vote')}
                </div>
              </button>
            )}
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default VoteButtons;
