const { gql } = require('apollo-boost');

const GET_METER_VOTE_RESULTS = gql`
  query meterVoteResults($module_id: Int!, $uid: String!) {
    meterVoteResults(module_id: $module_id, uid: $uid) {
      result
      value
    }
  }
`;
export default GET_METER_VOTE_RESULTS;
