import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import useShow from '../../../../hooks/useShow';
import { trackCustomEvent } from '../../../../library/gaHelper';

import PROCESS_PHONE_NUMBER from '../../../../graphql/mutations/processPhoneNumber';
import useWelcome from '../../../../hooks/useWelcome';
import TermsPage from '../../../Page/terms';

const PhoneNumberModal = (props) => {
  const {
    setStartVoteModalsQueue,
    setShowPinModal,
    optionData,
    setSecretMessage,
    phonenumber,
    setPhoneNumber,
    setErrorMessage,
    setHasError,
  } = props;
  const { language, currentModule, episodeInfo } = useShow();
  const { isTermsAccepted, setTermsStorage } = useWelcome();
  const { t } = useTranslation();
  const [processPhonenumber, { loading }] = useMutation(PROCESS_PHONE_NUMBER);
  const [isAccepted, setIsAccepted] = useState(isTermsAccepted());
  const [showTerms, setShowTerms] = useState(false);
  const iconClassList = ['far', 'fa-square'];
  if (isAccepted) {
    const index = iconClassList.indexOf('fa-square');
    if (index > -1) {
      iconClassList.splice(index);
    }
    iconClassList.push('fa-check-square');
  }
  let phonePrefix = '';
  switch (language) {
    case 'et':
      phonePrefix = '+372';
      break;
    case 'lv':
      phonePrefix = '+371';
      break;
    case 'en':
    default:
      phonePrefix = '';
      break;
  }
  useEffect(() => {
    trackCustomEvent({
      category: 'voting',
      action: 'Opened phone modal',
      label: optionData.title,
    }, [optionData.title]);
  });

  const processPhone = async () => {
    // Cannot use the regular setTermsAccepted from useWelcome(), because that would trigger a rerender
    setTermsStorage(isAccepted);
    const payload = {
      phonenumber,
      module_id: currentModule.id,
      option_id: optionData.id,
    };
    try {
      const result = await processPhonenumber({
        variables: {
          payload,
        },
      });
      if (result.data.processPhonenumber.success) {
        setSecretMessage(result.data.processPhonenumber.message);
        if (result.data.processPhonenumber.pinRequired) {
          setStartVoteModalsQueue(false);
          setShowPinModal(true);
        }
      } else {
        trackCustomEvent({
          category: 'voting',
          action: 'Entered Wrong Phonenumber',
          label: optionData.title,
        });
        setStartVoteModalsQueue(false);
        setHasError(true);
        setErrorMessage(t('modules.vote.form.error_process_phone'));
      }
    } catch (e) {
      setStartVoteModalsQueue(false);
      setHasError(true);
      setErrorMessage(t('modules.vote.form.error_process_phone'));
    }
  };

  return (
    <div className={loading ? 'modal-inner loading' : 'modal-inner'}>
      {loading && (
        <div className="modal-spinner">
          <Loader
            type="Grid"
            color="var(--color-primary)"
            height={100}
            width={100}
            style={{
              margin: 'auto auto',
            }}
          />
        </div>
      )}
      {!showTerms ? (
        <>
          <div className="modal__header">
            <div className="modal__title">
              <div className="modal__icon animated bounceIn">
                <i className="fas fa-mobile-alt" />
              </div>
            </div>
          </div>
          <div className="modal__content">
            <h2>
              {t('modules.vote.form.enter_phone')}
            </h2>
            <p>
              {t('modules.vote.form.enter_phone_detail')}
            </p>
            <div className="phone-input-container">
              <label htmlFor="phone-field">{phonePrefix}</label>
              <input
                value={phonenumber}
                type="number"
                className="modal__input swal2-input"
                id="phone-field"
                onChange={(event) => setPhoneNumber(event.target.value)}
              />
            </div>
            {episodeInfo.terms_location === 'before_purchase' && !isTermsAccepted() && (
              <div className="page__conditions">
                { isAccepted && (
                  <i className="fas fa-check animate-check" />
                )}
                <button type="button" onClick={() => setIsAccepted(!isAccepted)}>
                  <i className={iconClassList.join(' ')} />
                </button>
                {t('welcome.accept')}&nbsp;
                <button
                  type="button"
                  className="btn-link"
                  onClick={() => setShowTerms(true)}
                >
                  {t('welcome.terms_link')}
                </button>
              </div>
            )}
          </div>
          <div className="modal__actions">
            <button
              disabled={loading || !isAccepted}
              type="button"
              className="actions__confirm"
              onClick={() => processPhone()}
            >
              {t('modules.vote.form.confirm')}
            </button>
          </div>
        </>
      ) : (
        <TermsPage isModal setShowTerms={setShowTerms} />
      )}
    </div>
  );
};

export default PhoneNumberModal;
