import { gql } from 'apollo-boost';

const COMMENT_UPVOTE_ADD = gql`
  mutation addCommentUpvote($comment_id: Int!, $payload: CommentUpvoteInput!) {
    addCommentUpvote(comment_id: $comment_id, payload: $payload) {
      id
      total_upvotes
      updatedAt
    }
  }
`;

const COMMENT_UPVOTE_REMOVE = gql`
  mutation removeCommentUpvote($comment_id: Int!, $payload: CommentUpvoteInput!) {
    removeCommentUpvote(comment_id: $comment_id, payload: $payload) {
      id
      total_upvotes
      updatedAt
    }
  }
`;

export {
  COMMENT_UPVOTE_ADD,
  COMMENT_UPVOTE_REMOVE,
};
