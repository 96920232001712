import { gql } from 'apollo-boost';

const COMMENT_CREATE = gql`
    mutation createComment($payload: CommentCreateInput!) {
        createComment(payload: $payload) {
            id,
            uid,
            author,
            text,
            parent_id,
            createdAt,
        }
    }
`;

export default COMMENT_CREATE;
