import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import MetaTags from 'react-meta-tags';

import './i18n';
import './assets/styles/styles.scss';
import App from './containers/App';
import RootProvider from './context/root-context';
import InitProvider from './context/init-context';
import ModalProvider from './context/modal-context';
import WelcomePage from './components/Page/Welcome';
import WelcomeTerms from './components/Page/Welcome/terms';
import PrivateRoute from './components/PrivateRoute';
import Tracking from './components/Tracking';
import UpvoteContextProvider from './context/upvote-context';

import { initializeGoogleAnalytics } from './library/gaHelper';

initializeGoogleAnalytics();
// set up GraphQL client, to be used in rest of application
const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  connectToDevTools: true,
});

const iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

ReactDOM.render(
  <ApolloProvider client={client}>
    <RootProvider>
      <InitProvider>
        <ModalProvider>
          <UpvoteContextProvider>
            <BrowserRouter>
              <Tracking>
                {iOS && (
                  <MetaTags>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                  </MetaTags>
                )}
                <Switch>
                  <Route exact path="/welcome" component={WelcomePage} />
                  <Route path="/conditions">
                    <WelcomeTerms pageType="terms" />
                  </Route>
                  <PrivateRoute path="/" component={App} />
                </Switch>
              </Tracking>
            </BrowserRouter>
          </UpvoteContextProvider>
        </ModalProvider>
      </InitProvider>
    </RootProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
