import { useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import VOTE_ADD from '../../../../graphql/mutations/voteAdd';
import useShow from '../../../../hooks/useShow';
import { trackCustomEvent } from '../../../../library/gaHelper';
import PhoneNumberModal from './PhoneNumber';
import PinCodeModal from './PinCode';
import SuccessfulVote from './SuccessfulVote';
import ErrorModal from './VoteError';

const VoteModalPaid = ({ closeModal, optionData, setIsVoting, voteWeight, nextUrl }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentModule, trackingId } = useShow();
  const [createVote, { loading }] = useMutation(VOTE_ADD);
  const [secretMessage, setSecretMessage] = useState('');
  const [showPinModal, setShowPinModal] = useState(false);
  const [voted, setVoted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t('modules.vote.form.error_detail'));
  const [phonenumber, setPhoneNumber] = useState('');

  const [canShowResults, setCanShowResults] = useState(false);
  const [results, setResults] = useState(false);
  const [startVoteModalsQueue, setStartVoteModalsQueue] = useState(true);

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (loading) {
      setIsVoting(true);
    } else {
      setIsVoting(false);
    }
    // eslint-disable-next-line
  }, [loading]);
  useEffect(() => {
    if (showPinModal) {
      trackCustomEvent({
        category: 'voting',
        action: 'Pin modal open',
        label: optionData.title,
      });
    }
  }, [showPinModal, optionData.title]);
  const handleVoteAgainClick = (event) => {
    closeModal(event);
  };
  const handleClickNext = (event) => {
    closeModal(event);
    if (nextUrl) history.replace(nextUrl);
  };

  const postVote = async () => {
    const votePayload = {
      question_id: currentModule.id,
      answer_id: optionData.id,
      weight: voteWeight,
      is_paid: currentModule.is_paid,
      ip_address: phonenumber,
      tracking_id: trackingId,
    };
    try {
      const voteResult = await createVote({
        variables: {
          payload: votePayload,
          showResults: currentModule.show_results,
        },
      });
      if (voteResult.data.createVote.success) {
        setVoted(true);
        setShowPinModal(false);
        trackCustomEvent({
          category: 'voting',
          action: 'Successful Vote',
          label: optionData.title,
        });
        if (voteResult.data.createVote.showResults && voteResult.data.createVote.results) {
          setCanShowResults(true);
          setResults(voteResult.data.createVote.results);
        }
        return true;
      }
      trackCustomEvent({
        category: 'voting',
        action: 'Unsuccessful Vote',
        label: optionData.title,
      });
      return false;
    } catch (e) {
      setHasError(true);
      return false;
    }
  };

  const renderErrorModal = () => (
    <ErrorModal handleVoteAgainClick={handleVoteAgainClick} errorMessage={errorMessage} />
  );
  const renderSuccessfulVoteModal = () => {
    if (canShowResults && results) {
      return (
        <Redirect
          to={{
            pathname: `/${currentModule.id}`,
            state: {
              view: 'results',
              results,
              currentModule,
            },
          }}
        />
      );
    }
    return (
      <SuccessfulVote
        handleVoteAgainClick={handleVoteAgainClick}
        onClickNext={handleClickNext}
      />
    );
  };
  return (
    <div className="modal">
      {voted && (
        renderSuccessfulVoteModal()
      )}
      {hasError && (
        renderErrorModal()
      )}
      {startVoteModalsQueue && !hasError && (
        <PhoneNumberModal
          setStartVoteModalsQueue={setStartVoteModalsQueue}
          setShowPinModal={setShowPinModal}
          optionData={optionData}
          setSecretMessage={setSecretMessage}
          phonenumber={phonenumber}
          setPhoneNumber={setPhoneNumber}
          setErrorMessage={setErrorMessage}
          setHasError={setHasError}
        />
      )}
      {showPinModal && (
        <PinCodeModal
          phonenumber={phonenumber}
          secretMessage={secretMessage}
          optionData={optionData}
          voteWeight={voteWeight}
          setShowPinModal={setShowPinModal}
          setErrorMessage={setErrorMessage}
          setHasError={setHasError}
          postVote={postVote}
          setIsVoting={setIsVoting}
        />
      )}
    </div>
  );
};

export default VoteModalPaid;
