import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import imgModerator from '../../assets/img/moderator.png';

import { useTimeSince } from '../../hooks/time';
import useDoublePress from '../../hooks/useDoublePress';
import * as Styled from './ThreadReply.styled';

const UpvoteIcon = ({ hasUpvoted }) => (
  <FontAwesomeIcon
    icon={hasUpvoted
      ? solid('thumbs-up')
      : regular('thumbs-up')}
  />
);

const ThreadReply = ({
  content = '',
  author = '',
  authorColor = '#000000',
  moderatedAuthorColor = '#000000',
  upvoteColor = '#0000ff',
  timestamp = Date.now(),
  enableUpvotes = false,
  showTotalUpvotes = true,
  isModerated = false,
  hasUpvoted = false,
  totalUpvotes = 0,
  onAddUpvote = () => {},
  onRemoveUpvote = () => {},
}) => {
  const timeSince = useTimeSince(timestamp, 60 * 1000);

  const handleToggleUpvote = useCallback(() => {
    if (!enableUpvotes) return;

    if (hasUpvoted) onRemoveUpvote();
    else onAddUpvote();
  }, [onAddUpvote, onRemoveUpvote, hasUpvoted, enableUpvotes]);
  const handleDoublePress = useDoublePress(handleToggleUpvote, 300);

  return (
    <Styled.ReplyWrapper>
      <Styled.Container
        enableUpvotes={enableUpvotes}
        onPointerUp={handleDoublePress}
      >
        <Styled.Header>
          <Styled.HeaderAuthor
            isModerated={isModerated}
            color={authorColor}
            moderatedColor={moderatedAuthorColor}
          >
            {author}
          </Styled.HeaderAuthor>
          {isModerated && (
            <Styled.ModeratedIcon>
              <img src={imgModerator} alt="Moderated comment" />
            </Styled.ModeratedIcon>
          )}
          <Styled.HeaderTimestamp>{timeSince}</Styled.HeaderTimestamp>
        </Styled.Header>
        <Styled.ContentWrapper>
          <Styled.Content>{content}</Styled.Content>
          <Styled.ControlsWrapper>
            {enableUpvotes && (
              <Styled.UpvoteContainer upvoteColor={upvoteColor} hasUpvoted={hasUpvoted}>
                {showTotalUpvotes && totalUpvotes > 0 && (
                  <Styled.UpvoteTotal>{totalUpvotes}</Styled.UpvoteTotal>
                )}
                <Styled.UpvoteButton hasUpvoted={hasUpvoted} onClick={handleToggleUpvote}>
                  <UpvoteIcon hasUpvoted={hasUpvoted} />
                </Styled.UpvoteButton>
              </Styled.UpvoteContainer>
            )}
          </Styled.ControlsWrapper>
        </Styled.ContentWrapper>
      </Styled.Container>
    </Styled.ReplyWrapper>
  );
};

export default ThreadReply;
