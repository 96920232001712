import { useLazyQuery } from '@apollo/react-hooks';
import { useContext, useEffect, useMemo } from 'react';
import { RootContext } from '../context/root-context';
import { VOTES_WITH_OPTIONS } from '../graphql/queries/votes';
import useShow from './useShow';

const randomColor = (colors) => colors[Math.floor(Math.random() * colors.length)];
const keyOf = (word) => word.text.trim().toLowerCase();

const mapWords = (words) => words.reduce((wordsMap, word) => {
  const key = word.text.trim().toLowerCase();
  if (key in wordsMap) {
    const maxValue = Math.max(wordsMap[key].value, word.value);
    return {
      ...wordsMap,
      [key]: {
        ...wordsMap[key],
        value: maxValue,
      },
    };
  }
  return { ...wordsMap, [key]: word };
}, {});

const setWord = (word, wordsMap) => {
  Object.assign(wordsMap, {
    [keyOf(word)]: word,
  });
};

const processData = (data) => {
  if (!data.votes) return [];
  if (!data.votes.results) return [];
  if (!data.votes.results.options) return [];
  if (!data.votes.results.options.length) return [];

  const { options } = data.votes.results;
  return options.map(({ option, totalCount }) => ({
    text: option.title,
    value: totalCount,
  }));
};

const processWords = (words, wordsMap, colors = ['#000000']) => words.reduce((out, word) => {
  const key = keyOf(word);
  const w = wordsMap[key] || {
    text: word.text,
    value: word.value,
  };
  w.value = word.value;
  if (!word.color) {
    Object.assign(word, {
      color: randomColor(colors),
    });
  }
  setWord(word, wordsMap);
  return [...out, word];
}, []);

function hasUpdated(current, next) {
  if (!current || !next) return true;
  if (current.length !== next.length) return true;

  const mapped = mapWords(current);
  for (let i = 0; i < next.length; i += 1) {
    const word = next[i];
    const key = keyOf(word);
    const mappedWord = mapped[key];
    if (!mappedWord) {
      return true;
    }
    if (word.value !== mappedWord.value) {
      return true;
    }
  }
  return false;
}

const updateWordCloud = (oldState, moduleId, result) => ({
  ...oldState,
  WordCloud: {
    ...oldState.WordCloud,
    [moduleId]: result,
  },
});

function useWordCloud(moduleId, { colors = ['#000000'] }) {
  const { showInfo } = useShow();
  const [state, setState] = useContext(RootContext);
  const [loadData, {
    data,
    loading: dataLoading,
  }] = useLazyQuery(VOTES_WITH_OPTIONS, { fetchPolicy: 'no-cache' });
  useEffect(() => {
    if (moduleId && !data) {
      loadData({
        variables: { module_id: moduleId },
      });
    }
  }, [moduleId, data]);

  useEffect(() => {
    const interval = setInterval(async () => {
      await loadData({
        variables: { module_id: moduleId },
      });
    }, showInfo.poll_interval);

    return () => {
      clearInterval(interval);
    };
  }, [moduleId]);

  useEffect(() => {
    if (!moduleId) return;
    if (dataLoading || !data) return;

    const words = processData(data);
    const wordsMap = mapWords(words);
    const result = processWords(words, wordsMap, colors);

    if (!state.WordCloud || !state.WordCloud[moduleId]) {
      setState((oldState) => updateWordCloud(oldState, moduleId, result));
      return;
    }

    if (hasUpdated(state.WordCloud[moduleId], result)) {
      setState((oldState) => updateWordCloud(oldState, moduleId, result));
    }
  }, [moduleId, colors, dataLoading, data, state.WordCloud]);

  const wordCloud = useMemo(
    () => (state.WordCloud ? state.WordCloud[moduleId] : null),
    [moduleId, state.WordCloud],
  );

  return [
    wordCloud,
    {
      loading: dataLoading,
      refetch({ module_id }) {
        return loadData({
          variables: { module_id },
        });
      },
    },
  ];
}

export default useWordCloud;
