import { gql } from 'apollo-boost';

const REGISTER_VOTER = gql`
  mutation registerVoter($email: String!, $name: String!) {
    registerVoter(name: $name, email: $email) {
      id
      name
      email
    }
  }
`;

export default REGISTER_VOTER;
