import { gql } from 'apollo-boost';

const VIEWER_INFO = gql`
  query viewerInfo($domain: String!, $token: String!) {
    viewer(domain: $domain, token: $token) {
      id
      domain
      username
    }
  }
`;

export default VIEWER_INFO;
