import { gql } from 'apollo-boost';

const UPDATE_VIEWER = gql`
  mutation updateViewer($domain: String!, $token: String!, $payload: UpdateViewerInput!) {
    updateViewer(domain: $domain, token: $token, payload: $payload) {
      id
      domain
      username
    }
  }
`;

export default UPDATE_VIEWER;
