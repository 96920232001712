import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { sizes, zIndex, colors } from '../../../library/styleAbstracts/variables';
import useShow from '../../../hooks/useShow';


const CMenu = styled.div`
  position: absolute;
  top: ${sizes.headerHeight};
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.menu};
  background-color: ${colors.headerBG};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 5rem;
`;

const CMenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const CMenuLink = styled.li`
  border-top: 1px solid ${colors.menuBorderColor};
  text-align: center;
  line-height: 1.15;
  padding: 0.75rem;
  &:last-child {
    border-bottom: 1px solid ${colors.menuBorderColor};
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 900;
    color: ${(props) => (props.staticMenu ? colors.staticMenuItemColor : colors.moduleMenuItemColor)};
  }

  &::marker {
    content: "";
  }
`;

export default (props) => {
  const { modules, showInfo, episodeInfo } = useShow();

  const { isMenuVisible, setIsMenuVisible } = props;
  const { t } = useTranslation();
  const [shouldRender, setRender] = useState(isMenuVisible);
  useEffect(() => {
    if (isMenuVisible && modules) {
      setRender(true);
    }
  }, [isMenuVisible, modules]);
  const onAnimationEnd = () => {
    if (!isMenuVisible) {
      setRender(false);
    }
  };

  const renderShowInfoMenu = () => (
    <CMenuList>
      {showInfo && showInfo.display_info && (
        <CMenuLink staticMenu>
          <NavLink to="show-info" onClick={() => { setIsMenuVisible(false); }}>{showInfo.name}</NavLink>
        </CMenuLink>
      )}
      {episodeInfo.display_info && (
        <CMenuLink staticMenu>
          <NavLink to="episode-info" onClick={() => { setIsMenuVisible(false); }}>{episodeInfo.name}</NavLink>
        </CMenuLink>
      )}
    </CMenuList>
  );
  const renderModuleMenu = () => (
    modules.map((module) => (
      <CMenuLink key={module.id}>
        <NavLink to={`/${module.id}`} onClick={() => { setIsMenuVisible(false); }}>{module.title}</NavLink>
      </CMenuLink>
    ))
  );

  return (
    shouldRender && (
      <CMenu
        style={{ animation: `${isMenuVisible ? 'swoosh-left' : 'swoosh-left-out'} 0.25s` }}
        onAnimationEnd={onAnimationEnd}
        className="menu"
      >
        <PerfectScrollBar
          options={{
            wheelSpeed: 0.5,
            swipeEasing: false,
            suppressScrollX: true,
            wheelPropagation: false,
            scrollYMarginOffset: 10,
          }}
        >
          {((showInfo && showInfo.display_info) || episodeInfo.display_info) && (
            renderShowInfoMenu()
          )}
          <CMenuList style={{ marginTop: '1rem' }}>
            {renderModuleMenu()}
          </CMenuList>
          <CMenuList style={{ marginTop: '1rem' }}>
            <CMenuLink staticMenu>
              <NavLink to="/votemo" onClick={() => { setIsMenuVisible(false); }}>
                {t('pages.votemo.link_title')}
              </NavLink>
            </CMenuLink>
            <CMenuLink staticMenu>
              <NavLink to="/terms" onClick={() => { setIsMenuVisible(false); }}>
                {t('pages.terms.link_title')}
              </NavLink>
            </CMenuLink>
            <CMenuLink staticMenu>
              <a rel="noopener noreferrer" target="_blank" href="https://www.votemo.eu/privacy-policy" onClick={() => { setIsMenuVisible(false); }}>
                {t('pages.privacy.link_title')}
              </a>
            </CMenuLink>
          </CMenuList>
        </PerfectScrollBar>
      </CMenu>
    )
  );
};
