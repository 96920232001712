import { gql } from 'apollo-boost';

const VOTE_ADD = gql`
    mutation voteAdd($payload: VoteInput!, $showResults: Boolean = false) {
        createVote(payload: $payload, showResults: $showResults) {
            success,
            showResults,
            showTotalCount,
            totalCount,
            results @include(if: $showResults) {
                totalWeight,
                options {
                    id,
                    totalWeight
                }
            }
        }
    }
`;

export default VOTE_ADD;
