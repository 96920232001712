import { gql } from 'apollo-boost';

const COMMENT_VALIDATE = gql`
  mutation validateComment($payload: CommentCreateInput!) {
    validateComment(payload: $payload) {
      success
    }
  }
`;

export default COMMENT_VALIDATE;
