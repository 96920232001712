import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useEffect, useMemo, useState } from 'react';
import REGISTER_VIEWER from '../graphql/mutations/register-viewer.mutation';
import UPDATE_VIEWER from '../graphql/mutations/update-viewer.mutation';
import VIEWER_INFO from '../graphql/queries/viewer-info.query';
import showName from '../library/showName';

function useViewer(domain = showName()) {
  const [token, setToken] = useState(null);
  const [viewer, setViewer] = useState(null);
  const [loading, setLoading] = useState(true);

  const [loadViewer, { data }] = useLazyQuery(VIEWER_INFO);
  const [registerViewer] = useMutation(REGISTER_VIEWER);
  const [updateViewer] = useMutation(UPDATE_VIEWER);

  const userId = useMemo(
    () => (viewer ? viewer.id : null),
    [viewer],
  );

  // Load token on load, and register viewer if no token is saved
  useEffect(() => {
    if (!domain) return;

    const savedToken = localStorage.getItem(`votemo-show:${domain}:jwt`);
    if (!savedToken) {
      registerViewer({ variables: { domain } }).then((result) => {
        const { token: newToken } = result.data.registerViewer;
        localStorage.setItem(`votemo-show:${domain}:jwt`, newToken);
        setToken(newToken);
      });
      return;
    }

    setToken(savedToken);
  }, [domain]);

  // Update viewer on token change
  useEffect(() => {
    if (!token) return;

    loadViewer({ variables: { domain, token } });
  }, [token]);

  useEffect(() => {
    if (!data) return;
    setLoading(false);

    setViewer(data.viewer);
  }, [data]);

  const setUsername = async (username) => {
    updateViewer({ variables: { domain, token, payload: { username } } }).then((response) => {
      const { username: newUsername } = response.data.updateViewer;
      setViewer({
        ...viewer,
        username: newUsername,
      });
    });
  };

  const clearViewer = () => {
    localStorage.removeItem(`votemo-show:${domain}:jwt`);
  };

  return [viewer, {
    loading, userId, setUsername, clearViewer,
  }];
}

export default useViewer;
