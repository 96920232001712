import { gql } from 'apollo-boost';

const GET_COMMENTS = gql`
    query ($module_id: Int!, $parent_id: Int, $uid: String, $cursor: DateTime, $order: CommentsOrder) {
        comments(module_id: $module_id, parent_id: $parent_id, uid: $uid, cursor: $cursor, order: $order) {
            cursor
            comments {
                id
                uid
                author
                text
                createdAt
                is_hidden
                is_moderated
                total_upvotes
                has_upvoted
                parent_id
                replies {
                    comments {
                        id
                        author
                        text
                        createdAt
                        uid
                        is_hidden
                        parent_id
                        is_moderated
                        total_upvotes
                        has_upvoted
                    }
                }
            }
        }
    }

`;

export default GET_COMMENTS;
